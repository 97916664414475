import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/plugins/i18n';
import router from './router';
import LoadScript from 'vue-plugin-load-script';
import LoadingDialog from '@/components/LoadingDialog.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import BaseSelectDropdown from '@/components/BaseSelectDropdown.vue';
import Vue3TouchEvents from 'vue3-touch-events';

import App from './App.vue';
import '@/styles/app.css';
import '@/styles/fonts.css';
import '@/styles/scrollbar.css';

const app = createApp(App);
app.component('LoadingDialog', LoadingDialog);
app.component('BaseDialog', BaseDialog);
app.component('BaseSelectDropdown', BaseSelectDropdown);

const pinia = createPinia();
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(LoadScript);
app.use(Vue3TouchEvents);

app.mount('#app');

// Handling Global components TS types for intellisense
import { defineCustomElement } from '@vue/runtime-dom';

customElements.define('base-dialog', defineCustomElement(BaseDialog));
customElements.define('base-select-dropdown', defineCustomElement(BaseSelectDropdown));

declare module '@vue/runtime-core' {
	export interface GlobalComponents {
		'base-dialog': typeof BaseDialog,
		'base-select-dropdown': typeof BaseSelectDropdown,
	}
}