<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useThemeStore } from '@/stores/useThemeStore';
import { useScreenSize } from '@/composables/useScreenSize';
import { LANGUAGES } from '@/constants/translation';
import { Translation } from '@/plugins/Translation';
import IconClose from '@/components/icons/IconClose';

const { locale } = useI18n();
const route = useRoute();
const router = useRouter();
const { isMobile } = useScreenSize();
const themeStore = useThemeStore();
const {
  isShowNavigationMenu,
  isShowLanguageMenu,
} = storeToRefs(themeStore);

const languageMenu = computed(() => {
  return LANGUAGES.slice().map((item) => {
    return {
      label: item.abbr,
      iso: item.iso,
    };
  });
});

function toggleMenu () {
  if (!isShowLanguageMenu.value && isShowNavigationMenu.value) {
    themeStore.closeNavigationMenu();
  }
  themeStore.toggleShowLanguageMenu();
}
function closeMenu () {
  themeStore.closeLanguageMenu();
}
function changeLanguage (langIso: string) {
  locale.value = langIso;
  router.replace(Translation.i18nRoute({
    // name: route.name
  }));
  Translation.changeLanguage(langIso);
  closeMenu();
}
</script>

<template>
  <div>
    <button
      class="block md:hidden px-5"
      @click="toggleMenu"
    >
      <img
        :src="require(`@/assets/${locale}.png`)"
        class="w-6 h-6 md:w-[34px] md:h-auto object-cover md:object-none rounded-full"
      >
    </button>
    <div
      :class="[
        'absolute top-[63px] left-0 right-0 md:static w-full md:w-auto',
        'px-4 pb-5 md:p-0 border-y border-mcgard-gray-300 md:border-0',
        'bg-mcgard-gray-200 md:bg-transparent',
        isShowLanguageMenu || !isMobile ? 'translate-y-0 z-[1]' : '-translate-y-full z-[-1]',
        isMobile ? 'transition-all duration-200' : null,
      ]"
    >
      <div
        v-if="isMobile"
        class="flex justify-end"
      >
        <button
          class="py-2 px-4"
          @click="closeMenu"
        >
          <icon-close />
        </button>
      </div>
      <ul class="flex justify-center w-full md:w-auto mt-2 md:mt-0">
        <li
          v-for="(item, index) in languageMenu"
          :key="`langSwitcher${index}`"
          class="px-2"
        >
          <button
            class="flex flex-col items-center cursor-pointer group"
            @click="changeLanguage(item.iso)"
          >
            <img
              :src="require(`@/assets/${item.iso}.png`)"
              :class="[
                'w-[34px]',
                locale === item.iso ? null : 'grayscale group-hover:grayscale-0'
              ]"
            >
            <span
              :class="[
                'hidden md:block mt-1 uppercase text-[11px]',
                'group-hover:text-mcgard-primary',
                locale === item.iso ? 'text-mcgard-primary' : 'text-mcgard-gray-900'
              ]"
            >
              {{ item.iso }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>