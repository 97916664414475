import { apiClient } from '.';
import { KbaNumber } from '@/utils/types/configurators';

export const ConfiguratorService = {
  getCarsManufacturer() {
    return apiClient.get('/cars/manufacturers');
  },
  getCarsModel(manufacturer: string) {
    return apiClient.get('/cars/classes', {
      params: {
        manufacturer,
      },
    });
  },
  getCarsType(payload: { manufacturer: string, class: string }) {
    return apiClient.get('/cars/type-groups', {
      params: payload,
    });
  },
  getCarsVersion(type: string) {
    return apiClient.get('/cars/version-groups', {
      params: {
        group: type,
      },
    });
  },
  getCarByKbaNumber(payload: KbaNumber) {
    return apiClient.get('/cars/groups/german-code', {
      params: payload,
    });
  },
  getCarDetails(carTag: string) {
    return apiClient.get('/cars/car', {
      params: {
        carTag,
      },
    });
  },
  getCarImage(payload: {
    carTag: string,
    wheelTags: string,
    // eans: string[],
  }) {
    return apiClient.get('/configurator/image', {
      params: payload,
    });
  },
  getCarWheels(carTag: string) {
    return apiClient.get('/configurator/car-wheels', {
      params: {
        carTag,
        tyres: false,
      },
    });
  },
};