<script lang="ts" setup>
import { ref, watch } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import IconClose from '@/components/icons/IconClose.vue';

const props = withDefaults(defineProps<{
  modelValue: boolean,
  title?: string,
  maxWidth?: string,
  isWidthAuto?: boolean,
  isScrollable?: boolean,
}>(), {
  modelValue: false,
  title: '',
  maxWidth: 'max-w-md',
  isWidthAuto: true,
  isScrollable: false,
});

const emit = defineEmits(['update:modelValue']);
const selfValue = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  selfValue.value = newValue;
});

function closeModal () {
  selfValue.value = false;
  emit('update:modelValue', selfValue.value);
}
</script>

<template>
  <TransitionRoot
    appear
    :show="selfValue"
    as="template"
  >
    <Dialog
      as="div"
      class="relative z-10"
      @close="closeModal"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[
                'transform overflow-hidden rounded-2xl bg-white p-6 pt-7 text-left align-middle shadow-xl transition-all',
                isWidthAuto ? 'w-auto' : 'w-full',
                maxWidth,
                isScrollable ? 'flex flex-col h-auto max-h-[95vh]' : null,
              ]"
            >
              <a
                class="absolute top-4 right-4 cursor-pointer group"
                @click="closeModal"
              >
                <icon-close
                  class-stroke="stroke-mcgard-gray-500 group-hover:stroke-mcgard-primary"
                  :size="10"
                />
              </a>
              <DialogTitle
                v-if="title"
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ title }}
              </DialogTitle>
              <div
                v-if="isScrollable"
                class="overflow-y-auto px-px"
              >
                <slot />
              </div>
              <slot v-else />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>