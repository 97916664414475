export const topManufacturers = ['AUDI', 'BMW', 'MERCEDES-BENZ', 'FORD', 'VOLKSWAGEN'];
export const hiddenManufacturers = [
  'AIWAYS',
  'ALPINA',
  'BAIC',
  'BENTLEY',
  'BORGWARD',
  'BUGATTI',
  'DALLARA',
  'DFSK',
  'DR',
  'E-GO',
  'ELARIS',
  'FAW',
  'FERRARI',
  'KTM',
  'LADA',
  'LUCID',
  'MAN',
  'MAXUS',
  'MAYBACH',
  'MCLAREN',
  'ORA',
  'PONTIAC',
  'PROTON',
  'ROLLS-ROYCE',
  'STREETSCOOTER',
  'WEY',
  'ZOTYE',
];