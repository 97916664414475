import { StringKeyValue } from '@/utils/types';
import { PossibleLang, LangOption } from '@/utils/types/translation';
import { MC_GARD_API_URL } from '@/services';
import { i18n } from './i18n';
import { RouteLocationRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

export const Translation = {
  translationData: {} as LangOption,
  get defaultLocale () {
    return 'de';
  },
  get currentLocale() {
    return i18n.global.locale.value;
  },
  set currentLocale(locale) {
    i18n.global.locale.value = locale;
  },
  loadLanguageAsync(lang: string) {
    let localeFormat = '';
    if (lang === 'en') {
      localeFormat = 'en';
    } else if (lang === 'fr') {
      localeFormat = 'fr';
    // } else if (lang === 'es') {
    //   localeFormat = 'es';
    } else {
      localeFormat = 'de';
    }
    return fetch(`${MC_GARD_API_URL}translations/${localeFormat}`)
      .then((response) => response.json())
      .then((json) => json)
      .catch((err) => {
        console.error(
          `Can't get messages from /${lang}/translation: ${err.message}`,
        );
        return {};
      });
  },
  changeLanguage(lang: string) {
    const possibleLang = lang as PossibleLang;
    Translation.currentLocale = lang;
    if (Translation.translationData[possibleLang]) {
      i18n.global.setLocaleMessage(lang, {...Translation.translationData[possibleLang]});
      document.documentElement.lang = lang;
      return Promise.resolve(lang); // has been loaded prior
    }
    // i18n.global.setLocaleMessage(lang, {});
    // Translation.setTranslationData(lang, messages);
    // document.documentElement.lang = lang;
    // return Promise.resolve(lang);
    return Translation.loadLanguageAsync(lang).then((response) => {
      let messages = {} as any;
      Object.keys(response.data[lang]).forEach((key) => {
        if (response.data[lang][key].includes('@')) {
          let message = response.data[lang][key].slice().replaceAll('@', '{\'@\'}');
          message = message.replaceAll('|', '{\'|\'}');
          messages = {
            ...messages,
            [key]: message,
          };
        } else {
          messages = {
            ...messages,
            [key]: response.data[lang][key],
          };
        }
      });
      i18n.global.setLocaleMessage(lang, messages);
      // i18n.global.setLocaleMessage(lang, {...messages});
      Translation.setTranslationData(lang, messages);
      document.documentElement.lang = lang;
    });
  },
  setTranslationData (lang: string, data: StringKeyValue) {
    Translation.translationData = {
      ...Translation.translationData,
      [lang]: data,
    };
  },

  routeMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const lang = Array.isArray(to.params.locale) && to.params.locale.length > 0
      ? to.params.locale[0] || 'de'
      : to.params.locale.toString() || 'de';
    // Load async message files here
    return Translation.changeLanguage(lang).finally(() => { next(); });
  },
  i18nRoute(to: any): RouteLocationRaw {
    return {
      ...to,
      params: { ...to.params, locale: Translation.currentLocale },
    };
  },
};