<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { useThemeStore } from '@/stores/useThemeStore';
import { useConfiguratorStore } from '@/stores/useConfiguratorStore';
import { BreadcrumbItem } from '@/utils/types/breadcrumb';

const themeStore = useThemeStore();
const { filteredBreadcrumb } = storeToRefs(themeStore);
const configuratorStore = useConfiguratorStore();
const route = useRoute();
const router = useRouter();

function toDestination (item: BreadcrumbItem, index: number) {
  if (index === filteredBreadcrumb.value.length - 1) {
    return;
  }
  if (item.link) {
    router.push({
      name: item.link,
      query: item.query || {},
      params: item.params || {},
    });
  } else if (item.isSearchByKbaNumber) {
    configuratorStore.setIsSearchByKbaNumber(true);
    if (route.name !== 'configurator') {
      router.push({
        name: 'configurator',
        query: item.query || {},
        params: item.params || {},
      });
    }
  } else if (item.configuratorStep) {
    configuratorStore.setIsSearchByKbaNumber(false);
    configuratorStore.setStep(item.configuratorStep);
    if (route.name !== 'configurator') {
      router.push({ name: 'configurator' });
    }
  }
}
</script>

<template>
  <div
    v-if="filteredBreadcrumb.length > 0"
    class="w-full py-2 bg-mcgard-gray-400 px-5 md:px"
  >
    <a
      v-for="(item, index) in filteredBreadcrumb"
      :key="`breadcrumb${index}`"
      :class="[
        'text-xs uppercase',
        index < filteredBreadcrumb.length - 1 ? 'text-mcgard-gray-900' : 'text-mcgard-primary'
      ]"
    >
      <span
        :class="[
          index < filteredBreadcrumb.length - 1 ? 'cursor-pointer hover:font-semibold' : null
        ]"
        @click="toDestination(item, index)"
      >
        {{ $t(item.label) }}
      </span>
      <span
        v-if="index < filteredBreadcrumb.length - 1"
        class="mx-2 text-sm text-mcgard-gray-900"
      >
        >
      </span>
    </a>
  </div>
</template>