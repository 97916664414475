<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useScreenSize } from '@/composables/useScreenSize';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { useThemeStore } from '@/stores/useThemeStore';
import {
  headerNavigation,
  headerB2bNavigation,
} from '@/configs/components/navigations';
import IconBurger from '@/components/icons/IconBurger';
import IconClose from '@/components/icons/IconClose';

const route = useRoute();
const router = useRouter();
const customerStore = useCustomerStore();
const { isB2B } = storeToRefs(customerStore);
const themeStore = useThemeStore();
const {
  isShowNavigationMenu,
  isShowLanguageMenu,
} = storeToRefs(themeStore);
const { locale } = useI18n();
const { isMobile } = useScreenSize();

const menuGroup = computed(() => {
  return route.meta && route.meta.routeParent ? route.meta.routeParent : '';
});
const navigationMenu = computed(() => {
  if (isB2B.value) {
    return headerB2bNavigation.map((item) => {
      if (item.value === 'products') {
        return {
          internalLink: item.internalLink,
          label: item.label,
          value: item.value,
        };
      }
      return item;
    });
  } else {
    return headerNavigation.map((item) => {
      if (item.value === 'products') {
        return {
          externalLink: item.externalLink,
          isNewTab: item.isNewTab,
          label: item.label,
          value: item.value,
        };
      }
      return item;
    });
  }
});

function toggleMenu () {
  if (!isShowNavigationMenu.value && isShowLanguageMenu.value) {
    themeStore.closeLanguageMenu();
  }
  themeStore.toggleShowNavigationMenu();
}
function closeMenu () {
  themeStore.closeNavigationMenu();
}
function goToPage (link: string) {
  themeStore.closeNavigationMenu();
  router.push({ name: link });
}
</script>

<template>
  <div
    class="md:border-t md:border-mcgard-gray-300 lg:border-0"
  >
    <button
      class="block md:hidden px-5"
      @click="toggleMenu"
    >
      <icon-burger />
    </button>
    <div
      :class="[
        'absolute top-[63px] left-0 right-0 md:static flex flex-col',
        'w-full md:w-auto h-[calc(100%_-_63px)] md:h-full',
        'border-y border-mcgard-gray-300 md:border-0',
        'bg-mcgard-gray-200 md:bg-transparent z-[5]',
        isShowNavigationMenu || !isMobile ? 'translate-x-0' : '-translate-x-full',
        isMobile ? 'transition-all duration-200' : null,
      ]"
    >
      <div
        v-if="isMobile"
        class="flex justify-end"
      >
        <button
          class="py-2 px-4"
          @click="closeMenu"
        >
          <icon-close />
        </button>
      </div>
      <ul
        :class="[
          'flex items-center justify-center w-full md:w-auto h-full mx-auto',
          isMobile ? 'flex-col' : 'flex-row',
        ]"
      >
        <li
          v-for="(item, index) in navigationMenu"
          :key="`headerNav${index}`"
          :class="[
            'relative w-full md:w-auto h-auto md:h-full mx-4 uppercase text-2xl md:text-[13px] cursor-pointer group',
            'after:absolute after:content-[\'\'] after:bottom-0 after:left-0',
            'after:w-full after:h-[5px] after:bg-mcgard-primary',
            menuGroup === item.value && !isMobile ?
              'after:block'
              : 'after:hidden',
            isMobile ? null : 'hover:after:block',
          ]"
        >
          <a
            v-if="item.internalLink"
            :class="[
              'relative flex items-center justify-center h-full py-4 md:py-2 px-4 md:px-0',
              'text-center font-roboto-black-italic leading-none cursor-pointer',
              menuGroup === item.value && !isMobile ? 'text-mcgard-gray-600' : 'text-mcgard-gray-900',
              menuGroup === item.value && isMobile ? 'bg-mcgard-primary text-white' : null,
              menuGroup !== item.value && !isMobile ? 'group-hover:text-mcgard-primary' : null,
              isMobile ? 'group-hover:bg-mcgard-primary group-hover:text-white' : '',
            ]"
            :target="item.isNewTab ? '_blank' : undefined"
            @click="goToPage(item.internalLink)"
          >
            {{ $t(item.label) }}
          </a>
          <a
            v-else-if="item.externalLink"
            :class="[
              'relative flex items-center justify-center h-full py-4 md:py-2 px-4 md:px-0',
              'text-center font-roboto-black-italic leading-none cursor-pointer',
              menuGroup === item.value ? 'text-mcgard-gray-600' : 'text-mcgard-gray-900',
              menuGroup === item.value && isMobile ? 'bg-mcgard-primary text-white' : null,
              menuGroup !== item.value && !isMobile ? 'group-hover:text-mcgard-primary' : null,
              isMobile ? 'group-hover:bg-mcgard-primary group-hover:text-white' : '',
            ]"
            :target="item.isNewTab ? '_blank' : undefined"
            :href="item.externalLink[locale.toLowerCase()]"
          >
            {{ $t(item.label) }}
          </a>
          <a
            v-else
            :class="[
              'relative flex items-center justify-center h-full py-4 md:py-2 px-4 md:px-0',
              'text-center font-roboto-black-italic leading-none cursor-pointer',
              menuGroup === item.value ? 'text-mcgard-gray-600' : 'text-mcgard-gray-900',
              menuGroup === item.value && isMobile ? 'bg-mcgard-primary text-white' : null,
              menuGroup !== item.value && !isMobile ? 'group-hover:text-mcgard-primary' : null,
              isMobile ? 'group-hover:bg-mcgard-primary group-hover:text-white' : '',
            ]"
          >
            {{ $t(item.label) }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>