import { createI18n } from 'vue-i18n';


export const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,
  fallbackLocale: 'de',
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlInMessage: false,
  runtimeOnly: false,
});
