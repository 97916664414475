<script lang="ts" setup>
import { useRouter } from 'vue-router';
import NavigationMenu from './NavigationMenu.vue';
import LanguageSwitcher from './LanguageSwitcher.vue';
import Breadcrumb from './Breadcrumb.vue';

const router = useRouter();
</script>

<template>
  <div class="top-0 left-0 w-full z-[2]">
    <div
      class="
        grid grid-cols-[70px_1fr_70px] md:grid-cols-2 lg:grid-cols-3
        items-center justify-center w-full h-auto md:h-[142px] lg:h-[71px]
        py-2 md:py-0 lg:px-5 z-30 bg-mcgard-gray-200
      "
    >
      <router-link
        class="
          col-span-1 col-start-2 md:col-start-1 md:row-span-1
          ml-auto md:ml-0 mr-auto py-2.5 md:pl-5 lg:pl-0
        "
        :to="{ name: 'configurator' }"
      >
        <img
          class="w-[125px] h-auto max-h-[35px] md:max-h-[51px] md:w-auto"
          :src="require('@/assets/logo-mcgard.png')"
        >
      </router-link>
      <navigation-menu
        class="
          col-start-1 row-start-1
          md:col-span-2 md:row-start-2 lg:row-start-1 lg:col-span-1 lg:col-start-2
          md:w-full lg:w-auto md:h-[71px] lg:h-full
        "
      />
      <language-switcher
        class="
          col-span-1 row-span-1
          ml-auto mr-0 md:pr-5 lg:pr-0
        "
      />
    </div>
    <breadcrumb />
  </div>
</template>