<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  footerUrls,
} from '@/configs/footer';

const { locale } = useI18n();

const footerItems = computed(() => {
  return footerUrls.map((item: any) => {
    return {
      label: item.label,
      link: item.link[locale.value.toLowerCase()],
    };
  });
});
</script>

<template>
  <section class="w-full">
    <div
      class="w-full flex flex-col md:flex-row justify-center items-center h-auto md:h-10 py-2 bg-mcgard-gray-400 px-5 md:px"
    >
      <a
        v-for="(item, index) in footerItems"
        :key="`footerItems${index}`"
        class="my-1.5 md:mx-4 text-xs uppercase text-mcgard-gray-900"
        :href="item.link"
        target="_blank"
      >
        <span
          class="cursor-pointer hover:font-semibold"
          v-html="$t(item.label)"
        />
      </a>
    </div>
  </section>
</template>