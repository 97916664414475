import axios from 'axios';
export const BASE_API_URL = 'https://api.jfnet.de/v1/';
// const API_TOKEN = process.env.VUE_APP_API_TOKEN;
const API_TOKEN = 'sYSq3K8EN21BZwvWnOFdEcvyPQHvdQwsFxgI/wNPXBzGuUdGoOl3qI1HvJMLRxFePyI7omfih5d8YCk5CafGr7mh';
export const MC_GARD_API_URL = 'https://mc-gard-api-test.hosting.jfnet.de/api/';

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
  // withCredentials: true,
  headers: {
    token: API_TOKEN,
  },
});
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error', error.response);
    return Promise.reject(error);
  },
);

export const mcGardApiClient = axios.create({
  baseURL: MC_GARD_API_URL,
  // withCredentials: true,
  headers: {
    // token: API_TOKEN,
    // 'Access-Control-Allow-Origin': '*',
  },
});
mcGardApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error', error.response);
    return Promise.reject(error);
  },
);
