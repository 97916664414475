<script lang='ts'>
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { useThemeStore } from '@/stores/useThemeStore';
import { useLoadImg, useLoadFont } from '@/composables/usePreloadAsset';
import HeaderNav from '@/components/header/HeaderNav.vue';
import FooterSection from '@/components/FooterSection.vue';

function preloadAsset () {
  useLoadImg([
    require('@/assets/images/background.png'),
    require('@/assets/images/sliders/mcgard_slide_1.jpg'),
    require('@/assets/images/sliders/mcgard_slide_2.jpg'),
    require('@/assets/images/sliders/mcgard_slide_3.jpg'),
  ]).catch();

  useLoadFont([
    {
      name: 'Sofia Pro Black',
      path: `url(${require('@/assets/fonts/SofiaProBlackAz.otf')})`,
    },
    {
      name: 'Roboto Medium',
      path: `url(${require('@/assets/fonts/Roboto-Medium.ttf')})`,
    },
    {
      name: 'Roboto MediumItalic',
      path: `url(${require('@/assets/fonts/Roboto-MediumItalic.ttf')})`,
    },
    {
      name: 'Roboto Bold',
      path: `url(${require('@/assets/fonts/Roboto-Bold.ttf')})`,
    },
    {
      name: 'Roboto BoldItalic',
      path: `url(${require('@/assets/fonts/Roboto-BoldItalic.ttf')})`,
    },
    {
      name: 'Roboto Black',
      path: `url(${require('@/assets/fonts/Roboto-Black.ttf')})`,
    },
    {
      name: 'Roboto BlackItalic',
      path: `url(${require('@/assets/fonts/Roboto-BlackItalic.ttf')})`,
    },
  ]);
}

export default {
  components: {
    HeaderNav,
    FooterSection,
  },
  setup() {
    const themeStore = useThemeStore();
    const customerStore = useCustomerStore();
    const { filteredBreadcrumb } = storeToRefs(themeStore);

    // For developemnt purpose, B2B/B2C can be controled here.
    // const isB2B = window.location.hostname.includes('b2b');
    const isB2B = process.env.VUE_APP_IS_B2B || window.location.hostname.includes('b2b');
    if (isB2B) {
      customerStore.setIsB2B(true);
      sessionStorage.setItem('isB2B', 'true');
    } else {
      customerStore.setIsB2B(false);

      let savedSelectedWheelInfo = sessionStorage.getItem('savedSelectedWheelInfo');
      let fetchAftermarketArticlesParams = sessionStorage.getItem('fetchAftermarketArticlesParams');

      sessionStorage.clear();

      if (savedSelectedWheelInfo) {
        sessionStorage.setItem('savedSelectedWheelInfo', savedSelectedWheelInfo);
      }
      if (fetchAftermarketArticlesParams) {
        sessionStorage.setItem('fetchAftermarketArticlesParams', fetchAftermarketArticlesParams);
      }
    }

    return {
      filteredBreadcrumb,
    };
  },
  created() {
    preloadAsset();
  },
};
</script>

<template>
  <div class="flex flex-col md:static w-full h-auto lg:h-full min-h-full bg-white overflow-hidden">
    <header-nav />
    <div
      :class="[
        'overflow-auto lg:overflow-hidden',
        'bg-white',
        filteredBreadcrumb.length > 0 ?
          'h-auto min-h-[calc(100%_-_103px)] md:min-h-[calc(100%_-_182px)] lg:min-h-[calc(100%_-_111px)] lg:h-[calc(100%_-_111px)]'
          : 'h-auto min-h-[calc(100%_-_71px)] md:min-h-[calc(100%_-_142px)] lg:min-h-[calc(100%_-_71px)] lg:h-[calc(100%_-_71px)]',
      ]"
    >
      <div
        :class="[
          'h-auto min-h-[calc(100%_-_100px)] md:min-h-[calc(100%_-_40px)] lg:h-[calc(100%_-_40px)]',
        ]"
      >
        <router-view />
      </div>
      <footer-section />
    </div>
  </div>
</template>