export const footerUrls = [
  {
    label: 'IMPRINT',
    link: {
      de: 'https://mcgard.de/impressum',
      en: 'https://mcgard.de/en/imprint',
      fr: 'https://mcgard.de/fr/mentions-legales',
    },
  },
  {
    label: 'PRIVACY-POLICY',
    link: {
      de: 'https://mcgard.de/datenschutz',
      en: 'https://mcgard.de/en/privacy-policy',
      fr: 'https://mcgard.de/fr/protection-des-donnees',
    },
  },
  {
    label: 'GENERAL-TERMS-AND-CONDITIONS',
    link: {
      de: 'https://mcgard.de/agb',
      en: 'https://mcgard.de/en/general-terms-and-conditions',
      fr: 'https://mcgard.de/fr/cgv',
    },
  },
];