let errorCount = 0;
/**
 * When there's only one statement, you can drop the {} and the return
 * x => y is equivalent to x => { return y; }
 * @param {string} path
 */

interface AssetObject {
  name?: string,
  path?: string,
}
function checkImageOnLoad (path: string, loadProgress = null as any) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => {
      if (loadProgress !== null && loadProgress !== undefined) {
        loadProgress();
      }
      resolve(img);
    };
    img.onerror = () => {
      if (errorCount === 1) {
        reject(new Error('img404'));
      } else {
        if (loadProgress !== null && loadProgress !== undefined) {
          loadProgress();
        }
        resolve(new Image().src = '');
      }
      errorCount++;
    };
  });
}

/**
   * Load image from array of image path
   * @param {string[]} paths
   */
export function useLoadImg (paths: string[], loadProgress = null as any) {
  errorCount = 0;
  return Promise.all(paths.map((path) => checkImageOnLoad(path, loadProgress)));
}

/**
 * Load image from array of image path
  * @param {string[]} paths
  */
export async function optimisticLoadImg (paths: string[]) {
  const results = [];
  for (const path of paths) {
    const image = await checkImageOnLoadOptimistic(path);
    results.push(image);
  }
  return Promise.resolve(results);
}

/**
 * When there's only one statement, you can drop the {} and the return
 * x => y is equivalent to x => { return y; }
 * @param {string} path
 */
function checkImageOnLoadOptimistic (path: string) {
  // If have error more than 10 then that mean the picture is undefined
  // else maybe have a problem while fetching
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject('img404');
    };
    img.src = path;
  });
}

export async function useLoadFont (paths: AssetObject[]) {
  let response;
  await Promise.all(paths.map(checkFontOnLoad))
    .then(() => {
      response = true;
    })
    .catch((err) => {
      response = err;
      return Promise.reject(err);
    });
  return response;
}

function checkFontOnLoad ({ name, path }: AssetObject) {
  return new Promise((resolve, reject) => {
    const font = new FontFace(name || '', path || '');

    font.load()
      .then((loadedFont) => {
        document.fonts.add(loadedFont);
        resolve(null);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
