import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { useConfiguratorStore } from '@/stores/useConfiguratorStore';
import { Translation } from '@/plugins/Translation';
import MainLayout from '@/layouts/MainLayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale',
    component: MainLayout,
    beforeEnter: Translation.routeMiddleware,
    children: [
      // ...
      {
        path: '',
        redirect: {
          name: 'configurator',
        },
      },
      {
        path: 'configurator',
        name: 'configurator',
        component: () => import(/* webpackChunkName: "configurator" */ '../views/configurator/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'configurator/:carTag',
        name: 'configurator_carTag',
        component: () => import(/* webpackChunkName: "configurator" */ '../views/configurator/_carTag/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'configurator/:carTag/oe-steel-wheels',
        name: 'configurator_carTag-oe-steel-wheels',
        component: () => import(/* webpackChunkName: "configurator-oe-steel-wheels" */ '../views/configurator/_carTag/oe-steel-wheels/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'configurator/:carTag/oe-alloy-wheels',
        name: 'configurator_carTag-oe-alloy-wheels',
        component: () => import(/* webpackChunkName: "configurator-oe-alloy-wheels" */ '../views/configurator/_carTag/oe-alloy-wheels/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'configurator/:carTag/after-market-wheels',
        name: 'configurator_carTag-after-market-wheels',
        component: () => import(/* webpackChunkName: "configurator-after-market-wheels" */ '../views/configurator/_carTag/after-market-wheels/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'configurator/:carTag/after-market-wheels/products',
        name: 'configurator_carTag-after-market-wheels-products',
        component: () => import(/* webpackChunkName: "configurator-after-market-wheels-products" */ '../views/configurator/_carTag/after-market-wheels/products/index.vue'),
        meta: {
          routeParent: 'configurator',
        },
      },
      {
        path: 'products',
        name: 'products',
        component: () => import(/* webpackChunkName: "products" */ '../views/products/index.vue'),
        meta: {
          routeParent: 'products',
        },
      },
      {
        path: 'products/:articleVersion',
        name: 'products_articleVersion',
        component: () => import(/* webpackChunkName: "products_articleVersion" */ '../views/products/_articleVersion/index.vue'),
        meta: {
          routeParent: 'products',
        },
      },
      {
        path: 'products/:articleVersion/:articleNo',
        name: 'products_articleVersion_articleNo',
        component: () => import(/* webpackChunkName: "products_articleVersion_articleNo" */ '../views/products/_articleVersion/_articleNo/index.vue'),
        meta: {
          routeParent: 'products',
        },
      },
      // {
      //   path: 'account',
      //   name: 'account',
      //   component: () => import(/* webpackChunkName: "account" */ '../views/account/index.vue'),
      //   meta: {
      //     routeParent: 'account',
      //   },
      // },
      // {
      //   path: 'login',
      //   name: 'login',
      //   component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
      //   meta: {
      //     routeParent: 'login',
      //   },
      // },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      path: '/de/configurator',
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // console.log(process.env);
  const nextRoute = to.name?.toString() || '';
  const b2bOnlyRoutes = ['products', 'products_articleVersion', 'products_articleVersion_articleNo'];
  const customerStore = useCustomerStore();
  const { isB2B } = storeToRefs(customerStore);
  const sessionIsB2B = sessionStorage.getItem('isB2B')?.toString();
  customerStore.setIsB2B(sessionIsB2B === 'true');
  const routeArray = to.fullPath.split('/');
  if (routeArray.length > 2 && routeArray[1] === 'img') {
    next();
  } else if (b2bOnlyRoutes.includes(nextRoute)) {
    if (isB2B.value) {
      next();
    } else {
      window.location.href = '/';
    }
  } else {
    const configuratorStore = useConfiguratorStore();
    const isSearchByKbaNumber = sessionStorage.getItem('isSearchByKbaNumber');
    if (isSearchByKbaNumber) {
      configuratorStore.setIsSearchByKbaNumber(isSearchByKbaNumber === 'true');
    }
    next();
  }
});

export default router;
