import { defineStore } from 'pinia';
import { ConfiguratorService } from '@/services/ConfiguratorService';
import {
  CarManufacturer,
  CarTypeResponse,
  CarVersionResponse,
  SavedSelectedWheelInfo,
} from '@/utils/types/configurators';
import { KbaNumber } from '@/utils/types/configurators';
import { WheelDetail, KitManValue } from '@/utils/types/wheels';
import {
  topManufacturers,
  hiddenManufacturers,
} from '@/configs/manufacturers';

export const useConfiguratorStore = defineStore('configurator', {
  state: () => ({
    step: 1,
    isSearchByKbaNumber: false,
    manufacturers: [] as CarManufacturer[],
    models: [] as string[],
    types: [] as CarTypeResponse[],
    versions: [] as CarVersionResponse[],
    selectedManufacturer: '',
    selectedModel: '',
    selectedType: null as CarTypeResponse | null,
    selectedVersion: null as CarVersionResponse | null,
    carTag: '',
    carDetails: null as any,
    configuratorImage: {} as any,
    searchType: '',
    showKbaInfo: false,
    loadedCarImageLayers: {} as any,
    carWheels: [] as any,
    fetchAftermarketArticlesParams: {} as any,
    savedSelectedWheelInfo: {} as SavedSelectedWheelInfo,
  }),
  actions: {
    setStep (step: number) {
      this.step = step;
      this.isSearchByKbaNumber = false;

      switch (step) {
      case 1:
        this.selectedManufacturer = '';
        this.selectedModel = '';
        this.selectedType = null;
        this.selectedVersion = null;
        break;
      case 2:
        this.selectedModel = '';
        this.selectedType = null;
        this.selectedVersion = null;
        break;
      case 3:
        this.selectedType = null;
        this.selectedVersion = null;
        break;
      case 4:
        this.selectedVersion = null;
        break;
      }

      this.carTag = '';
      sessionStorage.setItem('isSearchByKbaNumber', this.isSearchByKbaNumber.toString());
    },
    setIsSearchByKbaNumber (isSearchByKbaNumber: boolean) {
      this.isSearchByKbaNumber = isSearchByKbaNumber;
      this.carTag = '';
      sessionStorage.setItem('isSearchByKbaNumber', this.isSearchByKbaNumber.toString());
    },
    setCarTag(carTag: string) {
      this.carTag = carTag;
    },
    setSelectedManufacturer (manufacturer: string) {
      this.selectedManufacturer = manufacturer;
    },
    setSelectedModel (model: string) {
      this.selectedModel = model;
    },
    setSelectedType (type: CarTypeResponse) {
      this.selectedType = type;
    },
    setSelectedVersion (version: CarVersionResponse) {
      this.selectedVersion = version;
    },
    setSearchType (searchType: string) {
      this.searchType = searchType;
    },
    setShowKbaInfo (showKbaInfo: boolean) {
      this.showKbaInfo = showKbaInfo;
    },
    async getCarsManufacturer () {
      const response = await ConfiguratorService.getCarsManufacturer();
      if (response.status === 200) {
        this.manufacturers = response.data.slice()
          .filter((item: string) => {
            return !hiddenManufacturers.includes(item);
          })
          .map((item: string) => {
            return {
              brand: item,
              isTop: topManufacturers.includes(item),
            };
          });
      }
      return response;
    },
    async getCarsModel (manufacturer: string) {
      const response = await ConfiguratorService.getCarsModel(manufacturer);
      if (response.status === 200) {
        this.models = response.data;
      }
      return response;
    },
    async getCarsType (model: string) {
      const response = await ConfiguratorService.getCarsType({
        manufacturer: this.selectedManufacturer,
        class: model,
      });
      if (response.status === 200) {
        this.types = response.data;
      }
      return response;
    },
    async getCarsVersion (type: string) {
      const response = await ConfiguratorService.getCarsVersion(type);
      if (response.status === 200) {
        this.versions = response.data;
      }
      return response;
    },
    async getCarDetailsByKbaNumber(payload: KbaNumber) {
      const response = await ConfiguratorService.getCarByKbaNumber(payload);
      if (response.status === 200) {
        if (response.data.length === 1) {
          this.carTag = response.data[0].tag;
        }
      }
      return response;
    },
    alterTopManufacturer (payload: any) {
      // do sth here
    },
    setLoadedCarImageLayer (loadedCarImageLayers: any) {
      const { key, data } = loadedCarImageLayers;
      this.loadedCarImageLayers = { key, data };
    },
    async getCarDetails (carTag: string) {
      const response = await ConfiguratorService.getCarDetails(carTag);
      if (response.status === 200) {
        this.carDetails = response.data;
      }
      return response;
    },
    async getCarWheels (carTag: string) {
      const response = await ConfiguratorService.getCarWheels(carTag);
      if (response.status === 200) {
        this.carWheels = response.data;
      }
      return response;
    },
    setFetchAftermarketArticlesParams(fetchParams: any) {
      this.fetchAftermarketArticlesParams = fetchParams;
    },
    setSelectedWheelInfo(wheelInfo: any) {
      this.savedSelectedWheelInfo = wheelInfo;
    },
  },
  getters: {
    afterMarketCarWheelData (stateData): WheelDetail[] {
      if (stateData.carWheels.length === 0) {
        return [];
      }
      return stateData.carWheels
        .sort((a: any, b: any) => {
          if (a.manufacturer === b.manufacturer){
            return 0;
          } else if (a.manufacturer > b.manufacturer) {
            return 1;
          } else {
            return -1;
          }
        })
        .map((wheelGroup: any) => {
          const kitMans: KitManValue = {};
          const picturePerspective = wheelGroup.wheels.length > 0 ?
            wheelGroup.wheels[0].picturePerspective.replace('{orientation}', 'cc').replace('{backgroundColor}', 'xxxxxx')
              .replace('{fileExtension}', 'png') : '';
          const showroomUrlTemplate = wheelGroup.wheels.length > 0
            ? wheelGroup.wheels[0].showroomUrlTemplate
            : '';
          const showroomAngles = wheelGroup.wheels.length > 0
            ? wheelGroup.wheels[0].showroomAngles
            : '';
          const diameterArray = [...new Set(wheelGroup.wheels.map((wheel: any) => wheel.diameter))];
          let isWinter = false;
          wheelGroup.wheels.forEach((wheel: any) => {
            if (wheel.winter) {
              isWinter = true;
            }

            if (wheel.kitMan) {
              if (kitMans[wheel.diameter] && !kitMans[wheel.diameter].includes(wheel.kitMan)) {
                kitMans[wheel.diameter].push(wheel.kitMan);
              } else if (!kitMans[wheel.diameter]) {
                kitMans[wheel.diameter] = [wheel.kitMan];
              }
            }
          });
          const diameters = diameterArray.filter((diameter: any) => {
            return kitMans[diameter] && kitMans[diameter].length;
          });

          return {
            design:  wheelGroup.design,
            color: wheelGroup.color,
            picturePerspective,
            showroomUrlTemplate,
            showroomAngles,
            isWinter,
            diameters: diameters,
            manufacturer: wheelGroup.manufacturer,
            kitMan: wheelGroup.wheels.length > 0 ? wheelGroup.wheels[0].kitMan : '',
            wheelTag: wheelGroup.wheels.length > 0 ? wheelGroup.wheels[0].wheelTag : '',
            kitMans,
            children: wheelGroup.wheels,
          };
        })
        .filter((item: WheelDetail) => item.diameters.length > 0);
    },
  },
});