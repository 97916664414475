export const headerNavigation = [{
  internalLink: 'configurator',
  label: 'CONFIGURATOR',
  value: 'configurator',
},{
  externalLink: {
    de: 'https://shop.mcgard.de/de/',
    en: 'https://shop.mcgard.de/en/',
    fr: 'https://shop.mcgard.de/fr/',
  },
  isNewTab: true,
  label: 'SHOP',
  value: 'shop',
},{
  externalLink: {
    de: 'https://shop.mcgard.de/de/automobil',
    en: 'https://shop.mcgard.de/en/automotive',
    fr: 'https://shop.mcgard.de/fr/automobile',
  },
  internalLink: 'products',
  isNewTab: true,
  label: 'PRODUCTS',
  value: 'products',
},{
  externalLink: {
    de: 'https://mcgard.de/faq',
    en: 'https://mcgard.de/en/faq',
    fr: 'https://mcgard.de/fr/faq',
  },
  isNewTab: true,
  label: 'FAQ',
  value: 'faq',
},{
  externalLink: {
    de: 'https://mcgard.de/kundenservice',
    en: 'https://mcgard.de/en/customer-service',
    fr: 'https://mcgard.de/fr/service-clientele',
  },
  isNewTab: true,
  label: 'SERVICE',
  value: 'service',
}];

export const headerB2bNavigation = [{
  internalLink: 'configurator',
  label: 'CONFIGURATOR',
  value: 'configurator',
},{
  externalLink: 'https://shop.mcgard.de/en/automotive',
  internalLink: 'products',
  isNewTab: true,
  label: 'PRODUCTS',
  value: 'products',
}];