import { defineStore } from 'pinia';

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    isB2B: false,
  }),
  actions: {
    setIsB2B (val: boolean) {
      this.isB2B = val;
    },
  },
});