<script setup lang="ts">
import IconCaret from '@/components/icons/IconCaret.vue';
import IconClose from '@/components/icons/IconClose.vue';
import Loading from 'vue3-loading-overlay/dist/index';

import { BaseSelectDropdownItem, BaseSelectDropdownItemState } from '@/utils/types/index';

import { onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: any[],
  placeholder?: string,
  width?: string,
  items: BaseSelectDropdownItem[] | [],
  className?: Array<string> | string,
  triggerClassName?: Array<string> | string,
  isRounded?: boolean,
  isLoading?: boolean,
  isLoadingInit?: boolean,
  divider?: boolean,
  customIcon?: boolean,
  isObjValue?: boolean,
  objKey?: string,
  isRelative?: boolean,
  isSingleSelected?: boolean,
  required?: boolean,
  topPlaceHolder?: boolean,
  disabled?: boolean,
}>(),
{
  placeholder: '',
  width: '',
  className: '',
  triggerClassName: '',
  isRounded: false,
  isLoading: false,
  isLoadingInit: false,
  divider: false,
  customIcon: false,
  isObjValue: false,
  objKey: 'key',
  isSingleSelected: false,
  isRelative: false,
  required: false,
  topPlaceHolder: false,
  disabled: false,
});

const emit = defineEmits<{
  (
    event: 'update:modelValue',
    data: any[]
  ): void,
  (event: 'on-select-item'): void,
  (event: 'on-clear'): void,
}>();

defineExpose({
  reset,
  setSelectedItemState,
});

const { t } = useI18n();

const itemsOptionState = ref([] as BaseSelectDropdownItemState[]);
const selectedItems = ref([] as BaseSelectDropdownItemState[]);
const isOpen = ref(false);
const dropdownRef = ref(null);

// Computed
const getSelectedLabelText = computed(() => {
  if (selectedItems.value.length === 0) {
    return '';
  }
  return selectedItems.value.slice().map((item) => t(item.label)).join();
});
const getSelectedValues = computed(() => {
  if (selectedItems.value.length === 0) {
    return [];
  }
  return selectedItems.value.slice().map((item) => item.value);
});

// Watchers
watch(() => props.items, () => {
  setItemsOptionState();
});
watch(() => props.modelValue, () => {
  if (props.modelValue.length === 0) {
    reset();
  }
});

// Methods
function initSetSelectedItemState() {
  if (itemsOptionState.value.length === 0) {
    return;
  }
  selectedItems.value = itemsOptionState.value.slice()
    .filter((item) => item.selected);
}
function setItemsOptionState() {
  if (props.items.length === 0) {
    return;
  }
  itemsOptionState.value = props.items.slice().map((item) => {
    const stringSelectedValues = !props.modelValue || props.modelValue.length === 0 ? [''] : props.modelValue.map((v) => v + '');
    const isSelected = stringSelectedValues.find((v: string) => {
      return v === (item.value + '');
    });
    if (!props.modelValue || props.modelValue.length === 0) {
      return {
        value: item.value,
        label: item.label,
        selected: !!isSelected,
      };
    } else {
      return {
        value: item.value,
        label: item.label,
        selected: stringSelectedValues.includes(item.value + ''),
      };
    }
  });
}
function toggleDropdown() {
  isOpen.value = !isOpen.value;
}
function hideDropdown() {
  isOpen.value = false;
}
function onSelectItem(item: BaseSelectDropdownItemState) {
  if (props.isSingleSelected) {
    itemsOptionState.value = props.items.slice().map((optionItem) => {
      if (optionItem.value === item.value) {
        return {
          value: optionItem.value,
          label: optionItem.label,
          selected: !item.selected,
        };
      } else {
        return {
          value: optionItem.value,
          label: optionItem.label,
          selected: false,
        };
      }
    });
    const foundedItem = itemsOptionState.value.find((optionItem) => optionItem.value === item.value);
    if (foundedItem && foundedItem.selected) {
      selectedItems.value = [foundedItem];
    } else {
      selectedItems.value = [];
    }
    emit('update:modelValue', getSelectedValues.value);
    emit('on-select-item');
    hideDropdown();
    return;
  }
  item.selected = !item.selected;
  let stringSelectedValues: string[] = [];
  if (props.isObjValue) {
    stringSelectedValues = props.modelValue.map((objV) => objV[props.objKey] + '');
  } else {
    stringSelectedValues = props.modelValue.map((v) => v + '');
  }

  const isDouble = stringSelectedValues.find((v) => {
    return v === (item.value + '');
  });

  if (item.selected) {
    if(isDouble) {
      return;
    }
    selectedItems.value = [
      ...selectedItems.value,
      item,
    ];
    emit('update:modelValue', getSelectedValues.value);
    emit('on-select-item');
    return;
  }

  let index = 0;
  if (props.isObjValue) {
    index = selectedItems.value.findIndex((selectItem) => (selectItem.value[props.objKey] + '') === (item.value[props.objKey] + ''));
  } else {
    index = selectedItems.value.findIndex((selectItem) => (selectItem.value + '') === (item.value + ''));
  }
  selectedItems.value.splice(index, 1);
  emit('update:modelValue', getSelectedValues.value);
  emit('on-select-item');
}
function reset() {
  selectedItems.value = [];
  // emit('update:modelValue', []);
}
function setSelectedItemState(item: BaseSelectDropdownItemState) {
  if (itemsOptionState.value.length === 0) {
    return;
  }
  onSelectItem(item);
}

/**
 * On start
 * */

// Listen to on click outside
onClickOutside(dropdownRef, () => {
  hideDropdown();
});

// Set option item status
setItemsOptionState();

// Set selectedItems state
initSetSelectedItemState();
</script>

<template>
  <div
    ref="dropdownRef"
    :class="[
      className,
      disabled ? 'pointer-events-none opacity-50' : null,
    ]"
  >
    <div
      :class="[
        'dropdown-trigger dropdown',
        isOpen
          ? isRelative
            ? 'is-open h-auto'
            : 'is-open h-10'
          : isRelative
            ? 'h-11 hover:shadow-border-mcgard-gray-300'
            : 'h-10 hover:shadow-border-mcgard-gray-300',
        triggerClassName
      ]"
    >
      <p
        v-if="placeholder.length && topPlaceHolder && selectedItems.length"
        :class="[
          'absolute w-full text-mcgard-gray-500 text-[12.8px] whitespace-nowrap text-clip overflow-hidden',
          'top-[-16px] left-0 text-[10px]'
        ]"
      >
        <span v-html="placeholder" /> <span v-if="required">*</span>
      </p>
      <button
        class="dropdown-toggle top-0 left-0 w-full bg-white px-2 flex items-center justify-between z-[1] text-clip overflow-hidden"
        :class="[
          isRounded ? 'rounded' : null,
          isOpen
            ? 'after:absolute after:w-select-dropdown-container after:h-0.5 after:bg-mcgard-gray-200 after:bottom-px after:left-1/2 after:transfrom after:-translate-x-1/2 after:z-[2]'
            : null,
          isRelative
            ? 'relative h-10'
            : 'absolute h-full',
        ]"
        @click="toggleDropdown"
      >
        <div
          v-if="topPlaceHolder"
          class="w-full flex items-baseline"
        >
          <p
            v-if="placeholder.length"
            :class="[
              'absolute text-mcgard-gray-500 text-[12.8px] whitespace-nowrap label',
              selectedItems.length ? 'top-[-16px] left-0 text-[10px]' : 'top-1/2 transform -translate-y-1/2'
            ]"
          >
            <span v-html="placeholder" /> <span v-if="required">*</span>
          </p>
          <template v-if="selectedItems.length">
            <span
              v-if="selectedItems.length > 1"
              class="text-[12.8px] text-mcgard-gray-800 whitespace-nowrap label"
            >
              {{ selectedItems.length }} {{ $t('ITEMS') }}
            </span>
            <span
              v-else
              class="text-[12.8px] text-mcgard-gray-800 whitespace-nowrap label"
            >
              {{ getSelectedLabelText }}
            </span>
          </template>
        </div>
        <div
          v-else
          class="text-wrapper w-full flex items-baseline justify-between mr-2"
        >
          <p
            v-if="placeholder.length && selectedItems.length === 0"
            class="placeholder text-mcgard-gray-500 text-[12.8px] label"
          >
            <span v-html="placeholder" /> <span v-if="required">*</span>
          </p>
          <span
            v-if="selectedItems.length > 1"
            class="text-[12.8px] text-mcgard-gray-800 whitespace-nowrap label"
          >
            {{ selectedItems.length }} {{ $t('ITEMS') }}
          </span>
          <span
            v-else
            class="label text-[12.8px] text-mcgard-gray-800 whitespace-nowrap label"
          >
            {{ getSelectedLabelText }}
          </span>
        </div>
        <div class="h-full flex items-center justify-center">
          <span
            v-if="divider"
            class="h-[18px] w-px bg-mcgard-gray-200 mx-3"
          />
          <div>
            <transition
              mode="out-in"
              enter-active-class="transition duration-150"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition duration-150"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <icon-close
                v-if="isOpen"
                class-stroke="stroke-mcgard-gray-500"
                :size="10"
              />
              <slot
                v-else-if="customIcon"
                name="custom-icon"
                class="custom-icon"
              />
              <icon-caret
                v-else
                class="caret-icon"
                :size="24"
              />
            </transition>
          </div>
        </div>
      </button>
      <div
        v-if="isOpen"
        :class="[
          'left-0 w-full px-2 pb-2 max-h-[300px] overflow-y-auto bg-white shadow-border-mcgard-gray-300-no-top duration-500 z-[2]',
          isRounded ? 'rounded-b' : null,
          isRelative ? 'relative' : 'absolute top-full',
          isLoading || isLoadingInit ? 'min-h-[34px]' : null,
        ]"
      >
        <transition
          mode="out-in"
          enter-active-class="transition ease-out duration-100"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration 100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="isLoading"
            class="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-sm"
          >
            <loading
              color="var(--color-primary)"
              :is-full-page="false"
              :width="30"
              :height="30"
              active
            />
          </div>
        </transition>

        <transition
          mode="out-in"
          enter-active-class="transition ease-out duration-100"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in duration 100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="isLoadingInit && isOpen"
            class="relative h-full text-center py-10"
          >
            <loading
              color="var(--color-primary)"
              :is-full-page="false"
              :width="30"
              :height="30"
              active
            />
          </div>
          <div
            v-else-if="itemsOptionState.length === 0"
            class="h-full"
          >
            {{ $t('NO-RESULT') }}
          </div>
          <ul
            v-else
            class="h-full overflow-y-auto"
          >
            <li
              v-for="(item, index) in itemsOptionState"
              :key="`${item.label}-${index}`"
              :class="[
                'group border-b border-mcgard-gray-100 hover:bg-mcgard-primary-50',
                index === itemsOptionState.length - 1 ? 'border-b-0' : null
              ]"
              @click="onSelectItem(item)"
            >
              <button class="w-full flex items-center justify-between py-0.5 leading-6 text-left min-h-[26px]">
                <span
                  :class="[
                    'font-roboto-medium text-[14px]',
                    getSelectedValues.includes(item.value) ? 'text-mcgard-primary' : 'text-mcgard-gray-800'
                  ]"
                >
                  {{ $t(item.label) }}
                </span>
                <icon-close
                  v-if="getSelectedValues.includes(item.value)"
                  class-stroke="stroke-mcgard-primary"
                  :size="8"
                />
              </button>
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<style>
.dropdown {
  @apply relative w-full max-w-[350px] bg-white border border-mcgard-gray-200 transition-shadow duration-200;
}
.dropdown .caret-icon {
  @apply fill-mcgard-gray-500;
}
.dropdown:hover .label {
  @apply text-mcgard-primary;
}
.dropdown:hover .caret-icon .svg-fill {
  @apply fill-mcgard-primary;
}
.dropdown:hover .custom-icon .svg-fill {
  @apply fill-mcgard-primary;
}
.dropdown:hover .custom-icon .svg-stroke {
  @apply stroke-mcgard-primary;
}
</style>