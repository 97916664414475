import { defineStore } from 'pinia';
import { BreadcrumbItem } from '@/utils/types/breadcrumb';

export const useThemeStore = defineStore('breadcrumb', {
  state: () => ({
    breadcrumb: [] as BreadcrumbItem[],
    isShowNavigationMenu: false,
    isShowLanguageMenu: false,
  }),
  actions: {
    setBreadcrumb (items: BreadcrumbItem[]) {
      this.breadcrumb = items;
    },
    toggleShowNavigationMenu () {
      this.isShowNavigationMenu = !this.isShowNavigationMenu;
    },
    closeNavigationMenu () {
      this.isShowNavigationMenu = false;
    },
    toggleShowLanguageMenu () {
      this.isShowLanguageMenu = !this.isShowLanguageMenu;
    },
    closeLanguageMenu () {
      this.isShowLanguageMenu = false;
    },
  },
  getters: {
    filteredBreadcrumb (stateData): BreadcrumbItem[] {
      return stateData.breadcrumb.slice(-3);
    },
  },
});